"use client"

import { useCallback, useMemo, type FC } from "react"

import dayjs from "dayjs"
import { useQueryState } from "nuqs"

import Icon from "@/components/Icon"
import Paragraph from "@/components/typography/Paragraph"
import { cn } from "@/utils/cn"

import type { MonthPickerProps } from "./types"

const MonthPicker: FC<MonthPickerProps> = ({ className, onChange }) => {
  const [month, setMonth] = useQueryState("month")
  const [year, setYear] = useQueryState("year")

  const currentDate = useMemo(() => new Date(), [])
  const currentMonth = currentDate.getMonth()
  const currentYear = currentDate.getFullYear()

  const selectedDate = useMemo(() => {
    let date = new Date()

    if (month && year) {
      date = new Date(Number(year), Number(month) - 1)
      const isInFuture = dayjs(date).isAfter(currentDate)

      // If the date from the filters is in the future, reset it to the current date
      if (isInFuture) {
        setMonth((currentMonth + 1).toString())
        setYear(currentYear.toString())
        date = currentDate
      }
    }

    return date
  }, [month, year, currentDate, currentMonth, currentYear, setMonth, setYear])

  const isCurrentMonth =
    selectedDate.getMonth() === currentMonth &&
    selectedDate.getFullYear() === currentYear
  const monthLabel = selectedDate.toLocaleDateString("en-US", {
    month: "short",
  })
  const yearLabel = selectedDate.getFullYear().toString()

  const selectPreviousMonth = useCallback(() => {
    const previousMonth = dayjs(selectedDate).subtract(1, "month")

    setMonth((previousMonth.month() + 1).toString())
    setYear(previousMonth.year().toString())

    if (onChange) {
      onChange(previousMonth)
    }
  }, [selectedDate, setMonth, setYear, onChange])

  const selectNextMonth = useCallback(() => {
    const nextMonth = dayjs(selectedDate).add(1, "month")

    setMonth((nextMonth.month() + 1).toString())
    setYear(nextMonth.year().toString())

    if (onChange) {
      onChange(nextMonth)
    }
  }, [selectedDate, setMonth, setYear, onChange])

  return (
    <div
      className={cn(
        "flex h-9 items-center gap-x-2 rounded-md border border-border px-3 py-2 text-xs shadow-sm",
        className,
      )}
    >
      <button
        type="button"
        aria-label="Select previous month"
        onClick={selectPreviousMonth}
      >
        <Icon type="triangle-left" size="xs" className="text-muted" />
      </button>
      <Paragraph size="mono" className="w-16 select-none text-center uppercase">
        {monthLabel} {yearLabel}
      </Paragraph>
      <button
        type="button"
        aria-label="Select next month"
        onClick={selectNextMonth}
        disabled={isCurrentMonth}
      >
        <Icon type="triangle-right" size="xs" className="text-muted" />
      </button>
    </div>
  )
}

export default MonthPicker
