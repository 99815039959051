import { request } from "@/utils/request"

import { APIRoutes } from "./routes"

interface APIKeyData {
  api_key: string
}

export const getUserKeyAPI = async ({
  workspace_url,
}: {
  workspace_url: string
}) =>
  request<APIKeyData>(APIRoutes.GetUserKey, "POST", {
    includeUserParams: true,
    body: {},
    queryParam: {
      workspace_url,
    },
  })

export const refreshUserKeyAPI = async ({
  workspace_url,
}: {
  workspace_url: string
}) =>
  request<APIKeyData>(APIRoutes.RefreshUserKey, "POST", {
    includeUserParams: true,
    body: {},
    queryParam: {
      workspace_url,
    },
  })
