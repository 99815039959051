"use client"

import { type FC } from "react"

import { useSidebarItems } from "../hooks"
import { SidebarItems } from "../SidebarItems"

const MainItems: FC = () => {
  const items = useSidebarItems()

  return <SidebarItems items={items} className="uppercase" />
}

export default MainItems
