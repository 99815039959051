// src/components/layout/PageHeader/TeamSettingsHeader/TeamSettingsHeader.tsx

import type React from "react"
import { useCallback } from "react"

import { motion } from "framer-motion"
import { useRouter, usePathname, useParams } from "next/navigation"

import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs"
import { HEADER_ANIMATION_PROPS } from "@/constants"
import { type DefaultPageParams } from "@/types/globals"

const TeamSettingsHeader: React.FC = () => {
  const router = useRouter()
  const pathname = usePathname()
  const { account } = useParams<DefaultPageParams>()

  const getCurrentTab = useCallback(() => {
    const path = pathname.split("/")[3]
    return path || ""
  }, [pathname])

  const handleTabChange = useCallback(
    (value: string) => {
      router.push(`/${account}/settings/${value}`)
    },
    [router, account],
  )

  return (
    <motion.div
      {...HEADER_ANIMATION_PROPS}
      className="absolute left-1/2 top-[21px] -translate-x-1/2 transform"
    >
      <Tabs
        value={getCurrentTab()}
        onValueChange={handleTabChange}
        className="w-[348px]"
      >
        <TabsList>
          <TabsTrigger value="">GENERAL</TabsTrigger>
          {/* <TabsTrigger value="members">MEMBERS</TabsTrigger> */}
          <TabsTrigger disabled value="billing">
            BILLING
          </TabsTrigger>
        </TabsList>
      </Tabs>
    </motion.div>
  )
}

export default TeamSettingsHeader
