import dayjs, { type Dayjs } from "dayjs"

export const formatCents = (cents: number) =>
  new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
    .format(cents / 100)
    .replace(/,/g, "")

export const formatFloat = (number: number) => number.toFixed(2)

export const formatDate = (
  date: string | Date | Dayjs,

  type:
    | "natural"
    | "natural-with-time"
    | "short"
    | "day"
    | "chart"
    | "time"
    | "time-for-chat" = "natural",
) => {
  let format = ""

  if (type === "natural") {
    format = "MMM D, YYYY"
  } else if (type === "natural-with-time") {
    format = "MMM D, YYYY, h:mma"
  } else if (type === "short") {
    format = "M/D"
  } else if (type === "day") {
    format = "D"
  } else if (type === "chart") {
    format = "D MMM YYYY"
  } else if (type === "time") {
    format = "h:mma"
  } else if (type === "time-for-chat") {
    format = "h:m:s"
  }

  return dayjs(date).format(format)
}

export const formatSeconds = (seconds: number) => `${seconds.toFixed(2)}s`

export function hexToRGBA(hex: string, opacity: number) {
  const r = parseInt(hex.slice(1, 3), 16)
  const g = parseInt(hex.slice(3, 5), 16)
  const b = parseInt(hex.slice(5, 7), 16)

  return `rgba(${r}, ${g}, ${b}, ${opacity})`
}

export const formatLargeNumber = (num: number): string => {
  if (num >= 1000000) {
    return `${(num / 1000000).toFixed(1).replace(/\.0$/, "")}M`
  }
  if (num >= 1000) {
    return `${(num / 1000).toFixed(1).replace(/\.0$/, "")}K`
  }
  return num.toString()
}
