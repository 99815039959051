import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/common/ServerToast/ServerToast.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/layout/MainSidebar/ActiveCheck/ActiveCheck.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/layout/MainSidebar/APIkey/APIkey.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["useSidebarItems"] */ "/vercel/path0/src/components/layout/MainSidebar/hooks.ts");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/layout/MainSidebar/MainItems/MainItems.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/layout/MainSidebar/SidebarItems/SidebarItems.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/layout/MainSidebar/UserButton/SignOutButton/SignOutButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/layout/MainSidebar/WorkspaceDropdown/DefaultItem/DefaultItem.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/layout/MainSidebar/WorkspaceDropdown/WorkspaceInfo/WorkspaceInfo.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/layout/MainSidebar/WorkspaceDropdown/WorkspaceList/TeamWorkspaceList/TeamWorkspaceList.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/layout/MainSidebar/WorkspaceDropdown/WorkspaceList/UserWorkspaceList/UserWorkspaceList.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/layout/PageHeader/PageHeader.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/ui/dropdown-menu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/ui/ThemeToggle/ThemeToggle.tsx");
