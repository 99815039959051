import { type FC } from "react"

import { cn } from "@/utils/cn"

import { type ParagraphProps } from "./types"

const Paragraph: FC<ParagraphProps> = ({
  children,
  size = "default",
  className,
  id,
}) => (
  <p
    id={id}
    className={cn(
      "",
      size === "xs" && "text-xs",
      size === "sm" && "text-sm",
      size === "default" && "text-base",
      size === "lg" && "text-lg",
      size === "lead" &&
        "font-inter text-[1.125rem] font-medium leading-8 tracking-[-0.01em] ",
      size === "title" &&
        "font-inter text-[0.875rem] font-medium leading-5 tracking-[-0.02em]",
      size === "body" &&
        "font-inter text-[0.875rem] font-normal leading-5 tracking-[-0.02em]",
      size === "mono" &&
        "font-dmmono text-[0.75rem] font-normal leading-[1.125rem] tracking-[-0.02em]",
      size === "xsmall" &&
        "font-inter text-[0.75rem] font-normal leading-[1.0625rem] tracking-[-0.02em]",
      className,
    )}
  >
    {children}
  </p>
)

export default Paragraph
