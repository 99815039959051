"use client"

import type React from "react"

import { AnimatePresence, motion } from "framer-motion"

import Paragraph from "@/components/typography/Paragraph"
import { HEADER_ANIMATION_PROPS } from "@/constants"
import { cn } from "@/utils/cn"

import { type HeaderWrapperProps } from "./types"

const HeaderWrapper: React.FC<HeaderWrapperProps> = ({
  leftLabel,
  rightContent,
  className,
}) => (
  <div
    className={cn(
      "flex h-14 flex-shrink-0 border-b border-border px-3 py-2",
      className,
    )}
  >
    <AnimatePresence mode="wait" initial={false}>
      <motion.div
        key={leftLabel}
        {...HEADER_ANIMATION_PROPS}
        className="flex items-center"
      >
        {leftLabel && (
          <Paragraph size="mono" className="mb-0 uppercase text-primary">
            {leftLabel}
          </Paragraph>
        )}
      </motion.div>
    </AnimatePresence>
    <div className="ml-auto flex max-h-full items-center">
      <AnimatePresence mode="wait" initial={false}>
        {rightContent}
      </AnimatePresence>
    </div>
  </div>
)

export default HeaderWrapper
