"use client"

import { useCallback, type FC } from "react"

import * as Sentry from "@sentry/nextjs"
import { useQueryClient } from "@tanstack/react-query"
import { motion } from "framer-motion"
import { useParams } from "next/navigation"
import { toast } from "sonner"

import MonthPicker from "@/components/form/MonthPicker"
import { Button } from "@/components/ui/button"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"
import { CACHE_KEYS, HEADER_ANIMATION_PROPS } from "@/constants"
import { ViewMode, useRunContext } from "@/contexts/RunContext"
import { type DefaultPageParams } from "@/types/globals"
import { logError } from "@/utils/error"

import { reloadRunPage } from "./serverActions"

const RunHeader: FC = () => {
  const { setViewMode, viewMode, isShowingDetails, toggleDetails } =
    useRunContext()
  const params = useParams<DefaultPageParams>()
  const { workspace, id } = params
  const queryClient = useQueryClient()

  const reload = useCallback(async () => {
    const loadingToast = toast.loading("Reloading runs...")

    try {
      queryClient.invalidateQueries({
        queryKey: [],
      })
      queryClient.invalidateQueries({
        queryKey: [CACHE_KEYS.ALL_RUNS(params)],
      })
      await reloadRunPage({ workspace: workspace ?? "", id: id ?? "" })
      toast.success("Reloaded runs")
    } catch (error) {
      toast.error("Failed to reload runs")

      if (error) {
        Sentry.addBreadcrumb({
          level: "error",
          message: "Failed to reload runs",
          data: {
            workspace,
            id,
            params,
          },
        })
        logError(error as Error)
      }
    } finally {
      toast.dismiss(loadingToast)
    }
  }, [workspace, id, queryClient, params])

  return (
    <motion.div
      {...HEADER_ANIMATION_PROPS}
      className="flex items-center gap-x-3"
    >
      {id && (
        <Select
          onValueChange={(value) => setViewMode(value as ViewMode)}
          defaultValue={ViewMode.AutoDetect as string}
        >
          <SelectTrigger className="w-48">
            <SelectValue placeholder="Select view mode" />
          </SelectTrigger>
          <SelectContent className="text-md font-dmmono">
            <SelectItem
              value={ViewMode.AutoDetect}
              className="text-md font-dmmono"
            >
              Auto-detect
            </SelectItem>
            <SelectItem
              value={ViewMode.Markdown}
              className="text-md font-dmmono"
            >
              Markdown
            </SelectItem>
            <SelectItem value={ViewMode.Text} className="text-md font-dmmono">
              Text
            </SelectItem>
          </SelectContent>
        </Select>
      )}
      {/* <Button
        type="button"
        variant="secondary"
        className="h-10 w-36"
        onClick={toggleDetails}
      >
        {isShowingDetails ? "Hide" : "Show"} Details
      </Button> */}
      <MonthPicker />
      <Button
        type="button"
        icon="reload"
        variant="reload"
        className="h-10"
        onClick={reload}
        aria-label="Reload page"
      />
    </motion.div>
  )
}

export default RunHeader
