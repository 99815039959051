import { type UserData } from "@/types/User"

export const getUserName = (userData: UserData) =>
  userData?.username ?? userData?.email.split("@")[0] ?? "user"

export const getFirstName = (name: string) => name.split(" ")[0]

export const getInitials = (name: string) => {
  const parts = name.split(" ")
  const allInitials = parts.map((part) => part.charAt(0).toUpperCase())
  const firstTwoInitials = allInitials.slice(0, 1)

  return firstTwoInitials.join("")
}
