"use client"

import { type FC } from "react"

import { useParams } from "next/navigation"

import Icon from "@/components/Icon"
import { cn } from "@/utils/cn"

import type { ActiveCheckProps } from "./types"
import type { DefaultPageParams } from "@/types/globals"

const ActiveCheck: FC<ActiveCheckProps> = ({ matchRoutes, matchAgainst }) => {
  const { account, workspace } = useParams<DefaultPageParams>()

  const isActive =
    matchAgainst === "account"
      ? matchRoutes.includes(account)
      : matchRoutes.includes(workspace)

  return (
    <Icon
      type="check"
      size="xs"
      className={cn(
        "transition",
        isActive
          ? "text-primary-500 dark:text-primary-200"
          : "text-transparent group-hover:text-zinc-700 dark:text-transparent",
      )}
    />
  )
}

export default ActiveCheck
