import { type FC } from "react"

import { useRouter } from "next/navigation"

import { DropdownMenuItem as BaseDropdownMenuItem } from "@/components/ui/dropdown-menu"
import { cn } from "@/utils/cn"

import type { DropdownMenuItemProps } from "./types"

const DropdownMenuItem: FC<DropdownMenuItemProps> = ({
  children,
  className,
  href,
  isActive,
}) => {
  const router = useRouter()

  return (
    <BaseDropdownMenuItem
      className={cn(
        className,
        isActive
          ? "bg-zinc-100 text-emerald-400 data-[highlighted]:bg-zinc-100 dark:bg-white/10 dark:text-emerald-200 dark:data-[highlighted]:bg-white/10"
          : "hover:text-zinc-500 dark:hover:text-zinc-300",
      )}
      onClick={() => {
        router.push(href)
      }}
    >
      {children}
    </BaseDropdownMenuItem>
  )
}

export default DropdownMenuItem
