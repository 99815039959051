"use client"

import { type FC } from "react"

import Icon from "@/components/Icon"
import { useWorkspaceContext } from "@/contexts/WorkspaceContext"
import { cn } from "@/utils/cn"

const WorkspaceInfo: FC = () => {
  const { currentWorkspace } = useWorkspaceContext()

  return (
    <div className="flex w-full items-center gap-x-3">
      <div className="flex size-6 flex-shrink-0 items-center justify-center overflow-hidden rounded-sm bg-secondary text-xs text-primary">
        <Icon type="workspace" size="xs" />
      </div>

      <span
        className={cn(
          "select-none text-xs text-muted transition duration-300 ",
        )}
      >
        {currentWorkspace?.ws_name ?? "ALL WORKSPACES"}
      </span>
    </div>
  )
}

export default WorkspaceInfo
