"use client"

import {
  createContext,
  type FC,
  useState,
  useContext,
  useMemo,
  useCallback,
} from "react"

import { useParams } from "next/navigation"

import type {
  WorkspaceContextProviderProps,
  WorkspaceContextValue,
} from "./types"
import type { DefaultPageParams } from "@/types/globals"
import type { IWorkspace } from "@/types/Workspace"

const WorkspaceContext = createContext<WorkspaceContextValue | undefined>(
  undefined,
)

export const WorkspaceContextProvider: FC<WorkspaceContextProviderProps> = ({
  children,
}) => {
  const params = useParams<DefaultPageParams>()
  const [allWorkspaces, setAllWorkspaces] = useState<IWorkspace[]>([])

  const currentWorkspace = useMemo(
    () =>
      allWorkspaces.find((workspace) => workspace.url === params.workspace) ??
      null,
    [allWorkspaces, params],
  )

  const updateWorkspace = useCallback((updatedWorkspace: IWorkspace) => {
    setAllWorkspaces((previousWorkspaces) => [
      ...previousWorkspaces.filter(
        (workspace) => workspace.id_workspace !== updatedWorkspace.id_workspace,
      ),
      updatedWorkspace,
    ])
  }, [])

  const value = useMemo(() => {
    const val: WorkspaceContextValue = {
      allWorkspaces,
      currentWorkspace,
      updateWorkspace,
      createMultipleWorkspaces: (workspaces: IWorkspace[]) => {
        setAllWorkspaces((previousWorkspaces) => [
          ...previousWorkspaces,
          ...workspaces,
        ])
      },
    }

    return val
  }, [allWorkspaces, currentWorkspace, updateWorkspace])

  return (
    <WorkspaceContext.Provider value={value}>
      {children}
    </WorkspaceContext.Provider>
  )
}

export const useWorkspaceContext = () => {
  const context = useContext(WorkspaceContext)

  if (!context) {
    throw new Error(
      "`useWorkspaceContext` must be used within a `WorkspaceContextProvider`.",
    )
  }

  return context
}
