import { type JWT } from "next-auth/jwt"

import { APIRoutes } from "@/api/routes"
import { CACHE_KEYS } from "@/constants"
import { type APIActivityEvent } from "@/types/Activity"
import { type UserData } from "@/types/User"
import { request } from "@/utils/request"

import { formatActivityEvents } from "./format"

export const getUserAPI = () =>
  request<UserData>(APIRoutes.ReadUser, "POST", {
    includeUserParams: true,
    tags: [CACHE_KEYS.USER],
  })

export type WaitlistStatus = "pending" | "success"

interface WaitlistStatusData {
  status: WaitlistStatus
  message: string
}

export const addUserToWaitlistAPI = async (token?: JWT) =>
  request<WaitlistStatusData>(APIRoutes.AddToWaitlist, "POST", {
    token,
    includeUserParams: true,
  })

interface WaitlistCountData {
  count: number
}

export const getWaitlistCountAPI = () =>
  request<WaitlistCountData>(APIRoutes.WaitlistCount, "GET")

interface UpdateUserData {
  name: UserData["name"]
  username: UserData["username"]
}

export const updateUserAPI = (data: UpdateUserData) =>
  request<UserData>(APIRoutes.UpdateUser, "POST", {
    body: data,
    includeUserParams: true,
  })

export const getUserActivityAPI = (userID: number) =>
  request<APIActivityEvent[]>(APIRoutes.GetUserActivity, "POST", {
    body: {
      filter: { id_user: userID },
    },
    format: (data) => formatActivityEvents(data as APIActivityEvent[]),
    includeUserParams: true,
  })

interface SignOutData {
  status: "success" | "fail"
  message: string
}

export const signOutAPI = () =>
  request<SignOutData>(APIRoutes.SignOutUser, "POST")
