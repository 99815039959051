"use client"

import { useMemo } from "react"

import { useParams } from "next/navigation"
import { useSession } from "next-auth/react"

import { useTeamContext } from "@/contexts/TeamContext"
import { ROUTES } from "@/routes"
import { type DefaultPageParams } from "@/types/globals"

import { type SidebarItem } from "./SidebarItems"

export const useSidebarItems = (): SidebarItem[] => {
  const session = useSession()
  const params = useParams<DefaultPageParams>()
  const { currentTeam } = useTeamContext()

  return useMemo(() => {
    const { account, workspace } = params
    const isWorkspace = !!workspace
    const isTeam = !!account && account !== session.data?.user.username
    const isUser =
      !isTeam &&
      !isWorkspace &&
      (account === session.data?.user.username || account === undefined)

    let settingsRoute = ""
    let dashboardRoute = ""
    let sessionsRoute = ""
    let playgroundRoute = ""

    if (isUser) {
      dashboardRoute = ROUTES.UserDashboard
      settingsRoute = ROUTES.UserSettings
      sessionsRoute = ROUTES.UserSessions
      playgroundRoute = ROUTES.UserPlayground
    } else if (isWorkspace) {
      dashboardRoute = ROUTES.WorkspaceDashboard(params)
      settingsRoute = ROUTES.WorkspaceSettings(params)
      sessionsRoute = ROUTES.WorkspaceSessions(params)
      playgroundRoute = ROUTES.WorkspacePlayground(params)
    } else if (isTeam) {
      dashboardRoute = ROUTES.TeamDashboard(params)
      settingsRoute = ROUTES.TeamSettings(params)
      sessionsRoute = ROUTES.TeamSessions(params)
      playgroundRoute = ROUTES.TeamPlayground(params)
    }

    const sidebarItems: SidebarItem[] = [
      {
        label: "Dashboard",
        route: {
          href: dashboardRoute,
          isExactMatch: true,
        },
        icon: "dashboard",
      },
      {
        label: "Sessions",
        route: {
          href: sessionsRoute,
          matchAgainst: sessionsRoute,
          isExactMatch: false,
        },
        icon: "run",
      },
      {
        label: "Playground",
        route: {
          href: playgroundRoute,
          isExactMatch: true,
        },
        icon: "playground",
      },
      {
        label: "Settings",
        route: {
          href: settingsRoute,
          isExactMatch: false,
        },
        icon: "settings",
      },
    ]

    return sidebarItems
  }, [params, session.data])
}
