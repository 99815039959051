"use client"

import { useCallback, useMemo, type FC } from "react"

import { motion } from "framer-motion"
import debounce from "lodash/debounce"
import { useParams } from "next/navigation"
import { toast } from "sonner"

import MonthPicker from "@/components/form/MonthPicker"
import { Button } from "@/components/ui/button"
import { HEADER_ANIMATION_PROPS } from "@/constants"
import { logError } from "@/utils/error"

import type { DefaultPageParams } from "@/types/globals"

import { reloadCharts } from "./serverActions"

const DashboardHeader: FC = () => {
  const { workspace } = useParams<DefaultPageParams>()

  const reload = useCallback(async () => {
    const loadingToast = toast.loading("Reloading dashboard")

    try {
      await reloadCharts(workspace)
      toast.success("Reloaded dashboard")
    } catch (error) {
      toast.error("Oops, something went wrong whilst reloading the dashboard")

      if (error) {
        logError(error as Error)
      }
    } finally {
      toast.dismiss(loadingToast)
    }
  }, [workspace])

  const reloadDebounce = useMemo(() => debounce(() => reload(), 1000), [reload])

  return (
    <motion.div
      {...HEADER_ANIMATION_PROPS}
      className="flex items-center gap-x-3"
    >
      <MonthPicker onChange={() => reloadDebounce()} />
      <Button
        type="button"
        size="icon"
        variant="ghost"
        icon="reload"
        onClick={reload}
        aria-label="Reload charts"
      />
    </motion.div>
  )
}

export default DashboardHeader
