import type { ReactNode } from "react"

export enum ViewMode {
  AutoDetect = "AutoDetect",
  Markdown = "Markdown",
  Text = "Text",
}

export enum Tab {
  Run = "Run",
  Functions = "Functions",
  Details = "Details",
}

export interface RunContextValue {
  viewMode: ViewMode | null
  setViewMode: (viewMode: ViewMode) => void
  isShowingDetails: boolean
  setIsShowingDetails: (isShowingDetails: boolean) => void
  toggleDetails: () => void
  currentTab: Tab
  setCurrentTab: (tab: Tab) => void
}

export interface RunContextProviderProps {
  children: ReactNode
}
