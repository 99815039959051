"use client"

import { Suspense, useEffect, useState, type FC } from "react"

import dayjs from "dayjs"

import Tooltip from "@/components/common/Tooltip"
import Icon from "@/components/Icon"
import Paragraph from "@/components/typography/Paragraph"
import { initDayjs } from "@/utils/date"
import { formatDate } from "@/utils/format"

initDayjs()

const CurrentDateTime: FC = () => {
  const [currentDateTime, setCurrentDateTime] = useState(dayjs.utc())

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentDateTime(dayjs.utc())
    }, 1000)

    return () => clearInterval(interval)
  }, [])

  return (
    <Suspense fallback={<Icon type="loader" className="animate-spin" />}>
      <Tooltip
        content={
          <Paragraph size="body" className="tracking-wide">
            All times are in UTC [{formatDate(currentDateTime, "time")}]
          </Paragraph>
        }
      >
        <Paragraph
          size="mono"
          className="flex cursor-pointer items-center gap-1 rounded-md bg-secondary px-1.5 py-1"
        >
          <Icon type="globe" size="xs" color="muted" /> UTC
        </Paragraph>
      </Tooltip>
    </Suspense>
  )
}

export default CurrentDateTime
