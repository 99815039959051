"use client"

import { useCallback, type FC } from "react"

import { toast } from "sonner"

import { useToastContext } from "@/contexts/ToastContext"
import useOnce from "@/hooks/useOnce"

import { type ServerToastProps } from "./types"

/**
 * Used to render a toast inside a SSR page (once the component has hydrated on the client)
 */
const ServerToast: FC<ServerToastProps> = ({ type, message }) => {
  const { sonnerInitialized } = useToastContext()

  const createToast = useCallback(() => {
    if (type === "info") {
      toast.info(message)
    } else if (type === "success") {
      toast.success(message)
    } else if (type === "warning") {
      toast.warning(message)
    } else if (type === "error") {
      toast.error(message)
    }
  }, [type, message])

  /**
   * If the toast runs before Sonner is initialized, the toast won't render
   */
  useOnce(createToast, undefined, sonnerInitialized)

  return null
}

export default ServerToast
