"use client"

import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  type FC,
} from "react"

import { useAnimationFrame, useInView } from "framer-motion"
import Link from "next/link"
import { useParams, usePathname, useRouter } from "next/navigation"

import LoadingSpinner from "@/components/common/LoadingSpinner"
import { cn } from "@/utils/cn"

import type { SidebarItem, SidebarItemsProps } from "./types"

import SidebarListItem from "./SidebarListItem"

import ItemWrapper from "../ItemWrapper"

const SidebarItems: FC<SidebarItemsProps> = ({
  items,
  onReachedBottom,
  animate = true,
  isLoading,
  className,
  rightIconType,
}) => {
  const pathname = usePathname()
  const { id } = useParams()
  const router = useRouter()
  const bottomElementRef = useRef<HTMLLIElement>(null)
  const isBottomInView = useInView(bottomElementRef)

  const itemsRef = useRef<HTMLAnchorElement[]>([])
  const [originalPathname, setOriginalPathname] = useState(pathname)
  const [activePathname, setActivePathname] = useState(pathname)
  const [currentParam, setActiveParam] = useState<string | null>(null)

  useEffect(() => {
    if (id) {
      setActiveParam(id as string)
    }
  }, [items, id])

  const getIsItemActive = useCallback(
    ({ route: { isExactMatch, href, matchAgainst } }: SidebarItem) => {
      const pathnameToMatch = matchAgainst ?? href

      return isExactMatch
        ? pathnameToMatch === activePathname
        : activePathname.includes(pathnameToMatch)
    },
    [activePathname],
  )

  const activeItemIndex = useMemo(
    () => items.findIndex(getIsItemActive),
    [items, getIsItemActive],
  )
  const [activeBackgroundTop, setActiveBackgroundTop] = useState<number | null>(
    null,
  )
  const [activeBackgroundHeight, setActiveBackgroundHeight] = useState<
    number | null
  >(null)
  const [activeBackgroundWidth, setActiveBackgroundWidth] = useState<
    number | null
  >(null)
  const isLoadingActiveBackground =
    activeBackgroundTop === null &&
    activeBackgroundHeight === null &&
    activeBackgroundWidth === null
  const activeClassName =
    "text-primary/100 border-r z-2 border-brand transition-all duration-300 ease-in-out"

  const onItemClick = useCallback(
    (item: SidebarItem) => () => {
      if (!item.route.isExternal) {
        setActivePathname(item.route.href)
      }
    },
    [],
  )

  const updateActiveBackgroundStyles = useCallback(() => {
    const activeItem = itemsRef.current[activeItemIndex]

    if (activeItem) {
      setActiveBackgroundTop(activeItem.offsetTop)
      setActiveBackgroundHeight(activeItem.offsetHeight)
      setActiveBackgroundWidth(activeItem.offsetWidth)
    }
  }, [activeItemIndex])

  /**
   * Update active pathname whenever `pathname` changes
   */
  useEffect(() => {
    if (pathname !== originalPathname) {
      setActivePathname(pathname)
      setOriginalPathname(pathname)
    }
  }, [pathname, originalPathname])

  useAnimationFrame(updateActiveBackgroundStyles)

  useEffect(() => {
    if (isBottomInView && onReachedBottom) {
      onReachedBottom()
    }
  }, [isBottomInView, onReachedBottom])

  return (
    <ul className={cn("relative flex h-full flex-col gap-1", className)}>
      {items.map((item, index) => {
        const { route } = item
        const isActive = getIsItemActive(item)

        return (
          <ItemWrapper key={route.href}>
            <Link
              href={route.href}
              className={cn(
                "group flex h-full shrink-0 items-center gap-x-3 px-4 py-1",
                isActive && !animate && activeClassName,
              )}
              onClick={onItemClick(item)}
              ref={(el) => {
                if (el) {
                  itemsRef.current[index] = el
                }
              }}
              target={route.isExternal ? "_blank" : undefined}
            >
              <SidebarListItem
                icon={item.icon}
                label={item.label}
                isActive={isActive}
                rightIconType={rightIconType}
              />
            </Link>
          </ItemWrapper>
        )
      })}
      {onReachedBottom && (
        <li ref={bottomElementRef}>
          <LoadingSpinner
            className={cn("size-6 transition", !isLoading && "opacity-0")}
          />
        </li>
      )}
      {!isLoadingActiveBackground && animate && (
        <li
          className={cn(
            "absolute z-0 size-full p-2.5 transition-all duration-300 ease-out",
            activeClassName,
          )}
          style={{
            width: `${activeBackgroundWidth}px`,
            transform: `translateY(${activeBackgroundTop}px)`,
            height: `${activeBackgroundHeight}px`,
          }}
        />
      )}
    </ul>
  )
}

export default SidebarItems
