"use client"

import { useParams, usePathname } from "next/navigation"
import { useSession } from "next-auth/react"

import CurrentDateTime from "@/components/pages/DashboardPage/CurrentDateTime"
import { type DefaultPageParams } from "@/types/globals"

import DashboardHeader from "./DashboardHeader"
import { useActiveSidebarItem } from "./hooks"
import RunHeader from "./RunHeader"
import SettingsHeader from "./SettingsHeader/SettingsHeader"
import TeamSettingsHeader from "./TeamSettingsHeader/TeamSettingsHeader"

import HeaderWrapper from "../HeaderWrapper"

const PageHeader: React.FC = () => {
  const { account, workspace } = useParams<DefaultPageParams>()
  const session = useSession()
  const user = session.data?.user
  const pathname = usePathname()
  const activeSidebarItem = useActiveSidebarItem()

  const isUserDashboard = pathname === "/"
  const isWorkspaceDashboard = pathname === `/${account}/${workspace}`
  const isTeamDashboard =
    pathname === `/${account}` && account !== user?.username
  const showDashboard =
    isUserDashboard || isWorkspaceDashboard || isTeamDashboard

  const leftLabel = activeSidebarItem?.label ?? ""

  const isUserSettings = pathname === "/settings"
  const isTeamSettings = pathname.includes(`/${account}/settings`)
  const rightContent = (
    <div className="flex items-center gap-3">
      <CurrentDateTime />
      {showDashboard && <DashboardHeader key="dashboard" />}
      {pathname.includes("/sessions") && <RunHeader key="session" />}
      {isUserSettings && <SettingsHeader key="settings" />}
      {pathname.includes("/settings/teams") && (
        <SettingsHeader key="settings" />
      )}
      {isTeamSettings && <TeamSettingsHeader key="team-settings" />}
    </div>
  )

  return activeSidebarItem ? (
    <HeaderWrapper leftLabel={leftLabel} rightContent={rightContent} />
  ) : null
}

export default PageHeader
