"use client"

import { type FC } from "react"

import { useRouter } from "next/navigation"

import { DropdownMenuItem } from "@/components/ui/dropdown-menu"
import { signOut } from "@/utils/user"

const SignOutButton: FC = () => {
  const router = useRouter()

  return (
    <DropdownMenuItem onClick={() => signOut(router)}>Log Out</DropdownMenuItem>
  )
}

export default SignOutButton
