import { type FC } from "react"

import Icon from "@/components/Icon"
import Paragraph from "@/components/typography/Paragraph"
import { cn } from "@/utils/cn"

import { type SideBarEachItemProps } from "./types"

const SidebarListItem: FC<SideBarEachItemProps> = ({
  label,
  icon,
  isActive,
  rightIconType,
  classname,
}) => (
  <div
    className={cn(
      "flex size-full shrink-0 select-none  items-center justify-between",
      classname,
    )}
  >
    <div className="flex items-center gap-x-4">
      {icon && (
        <Icon
          type={icon}
          size="xs"
          className={cn(
            " transition-colors duration-300 ",
            isActive ? "text-primary" : "text-muted group-hover:text-primary",
          )}
        />
      )}
      <Paragraph
        size="mono"
        className={cn(
          isActive
            ? "truncate text-primary "
            : "text-muted group-hover:text-primary",
        )}
      >
        {label}
      </Paragraph>
    </div>
    <div>
      {rightIconType && (
        <Icon
          type={rightIconType}
          size="xs"
          className={cn(
            "text-primary/100 transition-colors duration-300 ",
            isActive ? "text-primary" : "text-muted group-hover:text-primary",
          )}
        />
      )}
    </div>
  </div>
)
export default SidebarListItem
