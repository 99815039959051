"use client"

import { usePathname } from "next/navigation"

import { useSidebarItems } from "@/components/layout/MainSidebar/hooks"

export const useActiveSidebarItem = () => {
  const sidebarItems = useSidebarItems()
  const pathname = usePathname()
  const activeSidebarItem = sidebarItems.find((item) =>
    item.route.isExactMatch
      ? item.route.href === pathname
      : pathname.includes(item.route.href),
  )

  return activeSidebarItem
}
