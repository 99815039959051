"use client"

import {
  createContext,
  type FC,
  useCallback,
  useState,
  useContext,
  useMemo,
} from "react"

import {
  type ViewMode,
  type RunContextProviderProps,
  type RunContextValue,
  Tab,
} from "./types"

const RunContext = createContext<RunContextValue | undefined>(undefined)

export const RunContextProvider: FC<RunContextProviderProps> = ({
  children,
}) => {
  const [viewMode, setViewMode] = useState<ViewMode | null>(null)
  const [isShowingDetails, setIsShowingDetails] = useState(false)
  const [currentTab, setCurrentTab] = useState<Tab>(Tab.Run)

  const toggleDetails = useCallback(() => {
    setIsShowingDetails((prev) => !prev)
  }, [])

  const value = useMemo(() => {
    const val: RunContextValue = {
      viewMode,
      setViewMode,
      isShowingDetails,
      setIsShowingDetails,
      toggleDetails,
      currentTab,
      setCurrentTab,
    }

    return val
  }, [
    viewMode,
    isShowingDetails,
    setViewMode,
    toggleDetails,
    setIsShowingDetails,
    currentTab,
    setCurrentTab,
  ])

  return <RunContext.Provider value={value}>{children}</RunContext.Provider>
}

export const useRunContext = () => {
  const context = useContext(RunContext)

  if (!context) {
    throw new Error(
      "`useRunContext` must be used within a `RunContextProvider`.",
    )
  }

  return context
}
