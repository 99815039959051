"use client"

import { useEffect, useState, type FC } from "react"

import { useTheme } from "next-themes"

import { MoonIcon, SunIcon } from "./icons"
import { type ThemeToggleProps } from "./types"

const ThemeToggle: FC<ThemeToggleProps> = ({ as = "button", children }) => {
  const { resolvedTheme, setTheme } = useTheme()
  const otherTheme = resolvedTheme === "dark" ? "light" : "dark"
  const [mounted, setMounted] = useState(false)
  const Tag = as

  useEffect(() => {
    setMounted(true)
  }, [])

  return (
    <Tag
      className="flex items-center justify-between"
      aria-label={mounted ? `Switch to ${otherTheme} theme` : "Toggle theme"}
      onClick={() => setTheme(otherTheme)}
    >
      {children}
      <SunIcon className="size-5 stroke-zinc-500 dark:hidden" />
      <MoonIcon className="hidden size-5 stroke-primary dark:block" />
    </Tag>
  )
}

export default ThemeToggle
