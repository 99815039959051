import { type FC } from "react"

import { cn } from "@/utils/cn"

import { type ItemWrapperProps } from "./constants"

const ItemWrapper: FC<ItemWrapperProps> = ({ children, classname }) => (
  <div className={cn("z-1 h-9 hover:bg-secondary/80", classname)}>
    {children}
  </div>
)

export default ItemWrapper
