"use client"

import { type FC, type MouseEvent, useCallback, useState } from "react"

import { useParams } from "next/navigation"
import { toast } from "sonner"

import { getUserKeyAPI, refreshUserKeyAPI } from "@/api/auth"
import Tooltip from "@/components/common/Tooltip"
import Paragraph from "@/components/typography/Paragraph"
import { Button } from "@/components/ui/button"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { Input } from "@/components/ui/input"
import { Skeleton } from "@/components/ui/skeleton"
import useOnce from "@/hooks/useOnce"
import { type DefaultPageParams } from "@/types/globals"
import { logError } from "@/utils/error"
import { getWorkspaceUrl } from "@/utils/workspace"

import { APIKEY_ITEM } from "../constants"
import { SidebarListItem } from "../SidebarItems"

const APIkey: FC = () => {
  const [isShowingKey, setIsShowingKey] = useState(false)
  const [APIKey, setAPIKey] = useState<string | null>(null)
  const [isShowingRefreshUI, setIsShowingRefreshUI] = useState(false)
  const [loading, setLoading] = useState<boolean>(false)
  const { workspace } = useParams<DefaultPageParams>()

  const workspaceUrl = getWorkspaceUrl(workspace)
  const showKey = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setIsShowingKey((prev) => !prev)
  }, [])

  const regenerateAPIKey = useCallback(async () => {
    setLoading(true)
    try {
      const response = await refreshUserKeyAPI({
        workspace_url: workspaceUrl,
      })
      const key = response?.body?.api_key
      toast.success("Refreshed API Key")
      setIsShowingRefreshUI(false)

      if (key) {
        setAPIKey(key)
      }
    } catch (error) {
      toast.error("Oops, something went wrong whilst refreshing the API Key")

      if (error) {
        logError(error as Error)
      }
    } finally {
      setLoading(false)
    }
  }, [workspaceUrl])

  const onRefreshSubmit = useCallback(
    async (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()
      if (isShowingRefreshUI) {
        await regenerateAPIKey()
      } else {
        setIsShowingRefreshUI(true)
      }
    },
    [isShowingRefreshUI, regenerateAPIKey],
  )

  const copyKeyToClipboard = useCallback(
    (e: MouseEvent<HTMLInputElement>) => {
      e.preventDefault()
      e.stopPropagation() // Prevent the dropdown from closing
      if (APIKey) {
        navigator.clipboard.writeText(APIKey)
        toast.success("Copied API Key to clipboard")
      }
    },
    [APIKey],
  )

  const cancelRefreshAction = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()
      setIsShowingRefreshUI(false)
    },
    [],
  )

  const fetchAPIKey = useCallback(async () => {
    setLoading(true)
    try {
      const response = await getUserKeyAPI({ workspace_url: workspaceUrl })
      const key = response?.body?.api_key

      if (key) {
        setAPIKey(key)
      }
    } catch (error) {
      toast.error("Oops, something went wrong whilst fetching the API Key")

      if (error != null) {
        logError(error as Error)
      }
    } finally {
      setLoading(false)
    }
  }, [workspaceUrl])

  useOnce(() => {
    fetchAPIKey()
  })

  const maskedKey = APIKey ? "*".repeat(APIKey.length) : ""

  return (
    <DropdownMenu modal>
      <DropdownMenuTrigger className="size-full outline-none ring-0">
        <SidebarListItem
          key="API-KEY"
          {...APIKEY_ITEM}
          classname="gap-x-3 h-full px-4 py-1 "
        />
      </DropdownMenuTrigger>
      <DropdownMenuContent
        side="right"
        className="relative w-[21rem] border border-border  bg-background shadow-md "
      >
        <DropdownMenuItem className="flex w-full flex-col items-start gap-2 hover:bg-background">
          {isShowingRefreshUI ? (
            <>
              {loading ? (
                <Paragraph size="title" className="text-muted">
                  Refreshing API Key...
                </Paragraph>
              ) : (
                <Paragraph size="title" className="text-primary">
                  Confirm API key refresh
                </Paragraph>
              )}

              {loading ? (
                <Skeleton className="h-9 w-full animate-pulse bg-secondary/50" />
              ) : (
                <Paragraph size="body" className="text-muted">
                  This action is irreversible. Are you sure you want to refresh
                  your API Key?
                </Paragraph>
              )}
            </>
          ) : (
            <>
              <Paragraph size="title" className="text-muted">
                API Key
              </Paragraph>

              <div className="flex w-full items-center justify-between gap-3">
                {loading ? (
                  <Skeleton className="h-9 w-full animate-pulse bg-secondary/50" />
                ) : (
                  <Tooltip
                    delayDuration={0}
                    content={
                      <Paragraph size="body" className="text-accent">
                        Click to copy
                      </Paragraph>
                    }
                    side="bottom"
                    className="w-full"
                  >
                    <Input
                      type="text"
                      readOnly
                      onClick={copyKeyToClipboard}
                      value={isShowingKey ? APIKey! : maskedKey}
                      className=" disabled w-full cursor-default overflow-x-auto rounded-md border-none bg-transparent p-2 text-center text-sm  text-muted shadow-none outline-none hover:bg-secondary/80"
                    />
                  </Tooltip>
                )}

                <Button
                  variant="ghost"
                  icon={isShowingKey ? "eye-open" : "eye-closed"}
                  className="p-0"
                  onClick={showKey}
                />
              </div>
            </>
          )}

          <div className="flex w-full items-center justify-end gap-2 ">
            {isShowingRefreshUI && (
              <Button
                variant="outline"
                className="font-dmmono text-xs uppercase text-primary"
                onClick={cancelRefreshAction}
              >
                Cancel
              </Button>
            )}

            <Button
              variant={isShowingRefreshUI ? "destructive" : "outline"}
              icon={loading ? "loader" : "refresh"}
              className="font-dmmono text-xs uppercase"
              onClick={onRefreshSubmit}
            >
              Refresh
            </Button>
          </div>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export default APIkey
