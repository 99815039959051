import { ROUTES } from "@/routes"

import { type SidebarItem, type SideBarEachItem } from "./SidebarItems"

export const SECONDARY_SIDEBAR_ITEMS: SidebarItem[] = [
  {
    label: "Docs",
    route: {
      href: ROUTES.Docs,
      isExternal: true,
    },
    icon: "documentation",
  },
  {
    label: "Discord",
    route: {
      href: ROUTES.Discord,
      isExternal: true,
    },
    icon: "Discord",
  },
  {
    label: "GitHub",
    route: {
      href: ROUTES.GitHub,
      isExternal: true,
    },
    icon: "GitHub",
  },
]

export const APIKEY_ITEM: SideBarEachItem = {
  label: "API KEY",
  icon: "api",
  rightIconType: "caret-right",
}
