import type React from "react"
import { useCallback } from "react"

import { motion } from "framer-motion"
import { useRouter, usePathname } from "next/navigation"

import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs"
import { HEADER_ANIMATION_PROPS } from "@/constants"

const SettingsHeader: React.FC = () => {
  const router = useRouter()
  const pathname = usePathname()

  const getCurrentTab = useCallback(() => {
    const path = pathname.split("/")[2]
    return path || ""
  }, [pathname])

  const handleTabChange = useCallback(
    (value: string) => {
      router.push(`/settings/${value}`)
    },
    [router],
  )

  return (
    <motion.div
      {...HEADER_ANIMATION_PROPS}
      className="absolute left-1/2 top-[21px]   -translate-x-1/2 transform  "
    >
      <Tabs
        value={getCurrentTab()}
        onValueChange={handleTabChange}
        className="w-[348px]"
      >
        <TabsList>
          <TabsTrigger value="">USER</TabsTrigger>
          <TabsTrigger value="teams">TEAM</TabsTrigger>

          <TabsTrigger disabled value="billing">
            BILLING
          </TabsTrigger>
        </TabsList>
      </Tabs>
    </motion.div>
  )
}

export default SettingsHeader
