"use client"

import { type FC } from "react"

import { useParams } from "next/navigation"
import { useSession } from "next-auth/react"

import { DropdownMenuItem } from "@/components/ui/dropdown-menu"
import { useWorkspaceContext } from "@/contexts/WorkspaceContext"
import useOnce from "@/hooks/useOnce"

import type { UserWorkspaceProps } from "./types"
import type { DefaultPageParams } from "@/types/globals"

import Workspace from "../Workspace"

const UserWorkspaceList: FC<UserWorkspaceProps> = ({ workspaces }) => {
  const username = useSession().data?.user?.username!
  const { account } = useParams<DefaultPageParams>()
  const isAccount = account != null
  const isUserAccount = account === username
  const { createMultipleWorkspaces } = useWorkspaceContext()

  useOnce(() => {
    createMultipleWorkspaces(workspaces)
  })

  if (isAccount && isUserAccount && workspaces.length === 0) {
    return (
      <DropdownMenuItem disabled>No workspaces for this user</DropdownMenuItem>
    )
  }

  return (
    (account === username || account == null) &&
    workspaces.map(({ id_workspace, url, ws_name }) => (
      <Workspace
        key={id_workspace}
        account={account ?? username}
        url={url}
        name={ws_name}
      />
    ))
  )
}

export default UserWorkspaceList
