import dayjs from "dayjs"
import customParseFormat from "dayjs/plugin/customParseFormat"
import isLeapYear from "dayjs/plugin/isLeapYear"
import timezone from "dayjs/plugin/timezone"
import utc from "dayjs/plugin/utc"

import { debugLog } from "./log"

export const daysAgo = (days: number) => {
  const date = new Date()
  date.setDate(date.getDate() - days)
  return date
}

export const areSameDay = (date1: Date, date2: Date) =>
  date1.getFullYear() === date2.getFullYear() &&
  date1.getMonth() === date2.getMonth() &&
  date1.getDate() === date2.getDate()

export const getMinutes = (multiplier: number) => 60 * multiplier

export const getHours = (multiplier: number) => getMinutes(60) * multiplier

export const getDays = (multiplier: number) => getHours(24) * multiplier

let isInitialized = false

export const initDayjs = () => {
  if (!isInitialized) {
    debugLog("initDayjs()")
    dayjs.extend(isLeapYear)
    dayjs.extend(customParseFormat)
    dayjs.extend(utc)
    dayjs.extend(timezone)
    dayjs.tz.setDefault("UTC")

    isInitialized = true
  }
}

export const getLastDayOfMonth = (dateString: string) => {
  const [month, day] = dateString.split(" ")
  const date = new Date(`2024-${month}-01`)
  return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate()
}
