import { signOut as nextAuthSignOut } from "next-auth/react"
import { toast } from "sonner"

import { signOutAPI } from "@/api/user"
import { ROUTES } from "@/routes"

import type { AppRouterInstance } from "next/dist/shared/lib/app-router-context.shared-runtime"

import { logError } from "../error"

export const signOut = async (router: AppRouterInstance) => {
  let signedOut = false

  try {
    await nextAuthSignOut()
    await signOutAPI()
    signedOut = true

    if (typeof window !== "undefined") {
      // Ensure all browser tabs log out:
      window.dispatchEvent(new Event("storage"))
    }

    router.push(ROUTES.SignIn)
  } catch (error) {
    toast.error("Something went wrong whilst signing out")

    if (error) {
      logError(error as Error)
    }
  }

  return signedOut
}
