"use client"

import { type FC } from "react"

import { useParams } from "next/navigation"
import { useSession } from "next-auth/react"

import DropdownMenuItem from "@/components/common/DropdownMenu/DropdownMenuItem"
import { ROUTES } from "@/routes"

import type { DefaultPageParams } from "@/types/globals"

const DefaultItem: FC = () => {
  const username = useSession()?.data?.user?.username
  const { workspace, account } = useParams<DefaultPageParams>()

  const isActive = workspace == null
  const isUserDashboard = account === username || account == null
  const route = isUserDashboard
    ? ROUTES.UserDashboard
    : ROUTES.TeamDashboard({ account })

  return (
    <DropdownMenuItem href={route} isActive={isActive}>
      Default
    </DropdownMenuItem>
  )
}

export default DefaultItem
