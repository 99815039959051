"use client"

import { type FC } from "react"

import { useParams } from "next/navigation"
import { useSession } from "next-auth/react"

import { DropdownMenuItem } from "@/components/ui/dropdown-menu"
import { useWorkspaceContext } from "@/contexts/WorkspaceContext"
import useOnce from "@/hooks/useOnce"

import type { TeamWorkspaceListProps } from "./types"
import type { DefaultPageParams } from "@/types/globals"

import Workspace from "../Workspace"

const TeamWorkspaceList: FC<TeamWorkspaceListProps> = ({ workspaces }) => {
  const session = useSession()
  const { account } = useParams<DefaultPageParams>()
  const { createMultipleWorkspaces } = useWorkspaceContext()

  const username = session.data?.user?.username
  const isAccount = account != null
  const isUserAccount = account === username
  const isTeam = isAccount && !isUserAccount

  useOnce(() => {
    createMultipleWorkspaces(workspaces)
  })

  if (isTeam && workspaces.length === 0) {
    return (
      <DropdownMenuItem disabled>No workspaces for this team</DropdownMenuItem>
    )
  }

  return workspaces.map(
    ({ url, ws_name }) =>
      url === account && (
        <Workspace key={url} account={account} url={url} name={ws_name} />
      ),
  )
}

export default TeamWorkspaceList
