"use client"

import { type FC } from "react"

import DropdownMenuItem from "@/components/common/DropdownMenu/DropdownMenuItem"
import { useWorkspaceContext } from "@/contexts/WorkspaceContext"

import type { WorkspaceProps } from "./types"

const Workspace: FC<WorkspaceProps> = ({ account, url, name }) => {
  const { currentWorkspace } = useWorkspaceContext()
  const isActive = url === currentWorkspace?.url

  return (
    <DropdownMenuItem href={`/${account}/${url}`} isActive={isActive}>
      {name ?? url}
    </DropdownMenuItem>
  )
}

export default Workspace
