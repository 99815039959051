"use client"

import React, {
  createContext,
  type FC,
  useCallback,
  useState,
  useContext,
  useMemo,
  useEffect,
} from "react"

import { toast } from "sonner"

import type { ToastContextProviderProps, ToastContextValue } from "./types"

const ToastContext = createContext<ToastContextValue | undefined>(undefined)

/**
 * Contains initialization status for Sonner to prevent `<ServerToast>`s from
 * rendering until Sonner is ready
 */
export const ToastContextProvider: FC<ToastContextProviderProps> = ({
  children,
}) => {
  const [sonnerInitialized, setSonnerInitialized] = useState(false)
  const [offlineToast, setOfflineToast] = useState<string | number | null>(null)

  const finishedLoading = useCallback(() => {
    setSonnerInitialized(true)
  }, [])

  const value = useMemo(() => {
    const val: ToastContextValue = {
      sonnerInitialized,
      finishedLoading,
    }

    return val
  }, [sonnerInitialized, finishedLoading])

  const handleOnline = useCallback(() => {
    if (offlineToast) {
      toast.dismiss(offlineToast)
      setOfflineToast(null)
    }
  }, [offlineToast])

  const handleOffline = useCallback(() => {
    setOfflineToast(
      toast.error("You are offline. Please check your internet connection.", {
        dismissible: false,
      }),
    )
  }, [])

  useEffect(() => {
    window.addEventListener("online", handleOnline)
    window.addEventListener("offline", handleOffline)

    return () => {
      window.removeEventListener("online", handleOnline)
      window.removeEventListener("offline", handleOffline)
    }
  }, [handleOnline, handleOffline])

  return <ToastContext.Provider value={value}>{children}</ToastContext.Provider>
}

export const useToastContext = () => {
  const context = useContext(ToastContext)

  if (!context) {
    throw new Error(
      "`useToastContext` must be used within a `ToastContextProvider`.",
    )
  }

  return context
}
